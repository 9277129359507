<template>
  <div class="page-wrapper">
    <section class="help-wrap">
      <div class="img-wrap">
        <div class="co-founder vince">
          <p class="name">Vincent Boudeau</p>
          <p>Co-founder<br />Lead dev</p>
        </div>
        <div class="co-founder jal">
          <p class="name">Julien Aubrey</p>
          <p>Co-founder<br />Lead design</p>
        </div>
        <img alt="J&V" src="@/assets/img/jnv.jpg" />
      </div>
      <div class="text-wrap">
        <h1>We are here to help you!</h1>
        <ul>
          <li>You have a question?</li>
          <li>You forgot your password?</li>
          <li>You need help?</li>
          <li>You need to plan a demo?</li>
          <li>You have ideas for improvements?</li>
        </ul>
        <div class="contact-wrap">
          <p>Contact us</p>
          <a href="mailto:team@showadz.io">team@showadz.io</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head: function () {
    return {
      title: {
        inner: 'Contact Us',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} - Contact Us page`,
          id: 'desc',
        },
      ],
    }
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.help-wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4vw;

  .img-wrap {
    width: 50%;
    position: relative;

    .co-founder {
      position: absolute;
      bottom: 25px;
      .name {
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        color: #fff;
        font-size: 14px;
      }
      &.vince {
        left: 25px;
        text-align: left;
      }
      &.jal {
        right: 25px;
        text-align: right;
      }
    }

    img {
      width: 100%;
    }
  }

  .text-wrap {
    width: 50%;
    padding-left: 5vw;

    h1 {
      font-size: 30px;
      font-weight: 300;
      color: $dark-grey;
    }

    ul {
      list-style-type: none;
      padding: 0;
      width: 90%;
      margin: 2vw 0;

      li {
        background-color: #fff;
        font-size: 15px;
        color: $dark-grey;
        padding: 12px 15px;
        margin-bottom: 5px;
        border-radius: 3px;
      }
    }
  }

  .contact-wrap {
    p {
      color: $dark-grey;
      font-weight: 400;
      font-size: 16px;
    }
    a {
      font-weight: 600;
      font-size: 16px;
      color: $purple-blue;
    }
  }
}
</style>
